import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { registerUser } from '../../redux/slices/registrationSlice';


const Registration = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const registrationStatus = useSelector((state) => state.registration.status);
    const registrationError = useSelector((state) => state.registration.error);

    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check the password confirmation field
        if (formData.password !== formData.confirmPassword) {
            // Passwords don't match, set an error message
            dispatch({ type: 'registration/resetError' });
            dispatch({ type: 'registration/registerUser/rejected', error: { message: 'Passwords do not match' } });
            return; // Prevent further processing
        }

        if (!validateUsername(formData.username)) {
            dispatch({ type: 'registration/registerUser/rejected', error: { message: 'Invalid username' } });
            return;
        }

        try {
            const actionResult = await dispatch(registerUser(formData));
            // Check if the registration action succeeded
            if (registerUser.fulfilled.match(actionResult)) {
                // Registration successful, navigate to success page
                navigate('/registration-success');
            }
        } catch (error) {
            // Handle registration error
            console.error("Registration error:", error);
        }

    }

    useEffect(() => {
        // Check if registration status is 'succeeded' before clearing the form
        if (registrationStatus === 'succeeded') {
            // Clear form data and reset error message upon successful registration
            setFormData({
                username: '',
                email: '',
                password: '',
                confirmPassword: '',
            });

            // Reset error message
            dispatch({ type: 'registration/resetError' });
        } 

    }, [registrationStatus, dispatch]);

    function validateUsername(username) {
        const minLength = 3;
        const maxLength = 20;
        const allowedCharacters = /^[a-zA-Z0-9_-]+$/;
        const reservedWords = ['admin', 'root', 'superuser'];

        // Convert username to lowercase
        username = username.toLowerCase();
        
        // Check length
        if (username.length < minLength || username.length > maxLength) {
            return false;
        }
        
        // Check character set
        if (!allowedCharacters.test(username)) {
            return false;
        }
        
        // Check reserved words
        if (reservedWords.includes(username)) {
            return false;
        }
        
        // Add more validation rules as needed
        
        return true;
    }

    return (
        <div className="container-fluid">
            {/* Success message */}
            {/* {registrationStatus === 'succeeded' && <div className="alert alert-success">Registration successful!</div>} */}
            <div className="row mt-5 justify-content-center">
                <div className="col-lg-4">
                    <h2 className="text-center">Registration</h2>
                    <form onSubmit={handleSubmit}>
                        {registrationError && <div className="text-danger">{registrationError}</div>}
                        <div className="mb-3">
                            <label className="form-label" htmlFor="username">Username</label>
                            <input
                                className="form-control form-control-lg"
                                type="text"
                                id="username"
                                name="username"
                                autoComplete="username"
                                value={formData.username}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="email">Email</label>
                            <input
                                className="form-control form-control-lg"
                                type="email"
                                id="email"
                                name="email"
                                autoComplete="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="password">Password</label>
                            <input
                                className="form-control form-control-lg"
                                type="password"
                                id="password"
                                name="password"
                                autoComplete="current-password"
                                value={formData.password}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="confirmPassword">Confirm Password</label>
                            <input
                                className="form-control form-control-lg"
                                type="password"
                                id="confirmPassword"
                                name="confirmPassword"
                                autoComplete="current-password"
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="tos"
                                    name="tos"
                                    required
                                />
                                <label className="form-check-label" htmlFor="tos">
                                By clicking "Register", you acknowledge that you have read, understood,
                                and agree to be bound by these <Link to="/terms-of-service">Terms of Service</Link> and <Link to="/privacy-policy">Privacy Policy</Link>.
                                </label>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary btn-lg w-100" disabled={registrationStatus === 'loading'}>
                            {registrationStatus === 'loading' ? 'Registering...' : 'Register'}
                        </button>
                    </form>   
                </div>
            </div>    
        </div>
    );
};

export default Registration;