import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for register school email
export const registerSchoolEmail = createAsyncThunk('schoolEmailRegistration/registerSchoolEmail', async (
  { email, newPassword, schoolName, username, stripeConnectedAccountId }) => {
  const response = await fetch('/api/users/school-email-registration', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      newPassword,
      schoolName,
      username,
      stripeConnectedAccountId,
    }),
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.error);
  }

  return data
});

const registerSchoolEmailSlice = createSlice({
  name: 'schoolEmailRegistration',
  initialState: { status: 'idle', error: null },
  reducers: {
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerSchoolEmail.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(registerSchoolEmail.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.schoolEmailRegistration = action.payload;
      })
      .addCase(registerSchoolEmail.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { resetError } = registerSchoolEmailSlice.actions;
export default registerSchoolEmailSlice.reducer;
