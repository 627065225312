const getPayoutClassAndLabel = (status) => {
    if (!status) {
        return { className: 'text-secondary', label: 'Non-withdrawn' };
    }

    switch (status) {
        case 'paid':
            return { className: 'text-info', label: 'Successful' };
        case 'pending':
            return { className: 'text-warning', label: 'Pending' };
        case 'failed':
            return { className: 'text-danger', label: 'Failed' };
        case 'in_transit':
            return { className: 'text-warning', label: 'In Transit' };
        case 'cancelled':
            return { className: 'text-muted', label: 'Cancelled' };
        default:
            return { className: '', label: status };
    }
};

export default getPayoutClassAndLabel;
