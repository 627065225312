import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchContact } from '../../redux/slices/contactSlice';

function ContactForm() {
    const dispatch = useDispatch();

    const contactStatus = useSelector((state) => state.contact.status);
    const contact = useSelector((state) => state.contact.contact);
    const error = useSelector((state) => state.contact.error);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(fetchContact(formData));
    };

    // Clear form data when contact is successfully sent
    useEffect(() => {
        if (contactStatus === 'succeeded') {
            setFormData({ name: '', email: '', message: '' });
        }
    }, [contactStatus, contact]);

    return (
        <div className="container-fluid">
            <div className="row mt-5">
                <div className="col-lg-5 mx-auto">
                    <h2 className="">Contact</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="username">name</label>
                            <input
                                className="form-control form-control-lg"
                                type="text"
                                id="name"
                                name="name"
                                placeholder="Your Name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="username">Email</label>
                            <input
                                className="form-control form-control-lg"
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Your Email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="username">Message</label>
                            <textarea
                                className="form-control form-control-lg"
                                type="message"
                                id="message"
                                name="message"
                                placeholder="Your Message"
                                rows="6"
                                value={formData.message}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <button type="submit" className="btn btn-primary btn-lg w-100" disabled={contactStatus === 'loading'}>
                            {contactStatus === 'loading' ? 'Sending...' : 'Send'}
                        </button>
                        {error && <div className="text-danger mt-2">Error: {error}</div>} {/* Display error message */}
                        {contact && contactStatus === 'succeeded' && !error && (
                            <div className="text-success mt-2">{contact.message}</div>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
}



export default ContactForm;
