import 'bootstrap/dist/css/bootstrap.min.css';
import './ItemsForSale.css'

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchItemsForSale } from '../../redux/slices/itemsForSaleSlice';
import Hero from '../hero/Hero';
import FilterSchool from '../filterSchool/FilterSchool';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ItemsForSale({ searchQuery, onSearchQueryChange }) {
    const dispatch = useDispatch();

    const itemsForSale = useSelector((state) => state.itemsForSale.itemsForSale);
    const isLoggedIn = useSelector(state => state.authentication.isLoggedIn);

    useEffect(() => {
        dispatch(fetchItemsForSale());
    }, [dispatch]);

    // Filter items based on the search query and exclude sold or to_removal items
    const filteredItemsForSale = itemsForSale.filter((item) =>
        item.school_name.toLowerCase().includes(searchQuery.toLowerCase()) &&
        !item.sold && 
        !item.to_removal
    );

    // Check if all items are sold or to_removal
    const allItemsSoldOrToRemoval = itemsForSale.every(item => item.sold || item.to_removal);

    const isPageBlank = () => {
        return filteredItemsForSale.length === 0 && allItemsSoldOrToRemoval;
    };

    return (
        <div className="container-fluid">
            {filteredItemsForSale.length > 5 && (
                <div className="d-lg-none search-box position-fixed w-100 bg-white pt-3 pb-2 px-4 start-50 translate-middle-x z-3">
                    <FilterSchool searchQuery={searchQuery} onSchoolChange={onSearchQueryChange} />
                </div>
            )}
            <div className="container pt-3">
                {!isLoggedIn && <Hero isPageBlank={isPageBlank()}/>}
                <section className="" id="items-for-sale">
                    <div className="mt-5">
                        {
                            allItemsSoldOrToRemoval && !searchQuery && 
                            <div className="text-center">
                                <p className="">Can't spot anything you fancy? Why not flip those old school threads for some cash instead?</p>
                                {isLoggedIn && <Link to='/create-item-for-sale' type="button" className="btn btn-outline-primary btn-lg px-4">Sell now</Link>}
                            </div>
                        }
                        {filteredItemsForSale.length === 0 && searchQuery ? (
                            <p className="text-center">Can't spot anything you fancy? Why not flip those old school threads for some cash instead?"</p>
                        ) : (
                            <div className="row gx-4 gx-lg-5 pt-3">
                                {filteredItemsForSale.map((itemForSale) => (        
                                    <div key={itemForSale.id} className={`${itemForSale.sold || itemForSale.to_removal ? 'd-none' : ''} col-xxl-3 col-xl-4 col-lg-5 col-md-4 col-sm mb-3`}>
                                        <Link to={`/item-for-sale/${itemForSale.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                            <div className="card border-0 rounded-0 mb-3">
                                                <img 
                                                    src={`https://storage.googleapis.com/dropop/uploads/${itemForSale.images[0]}`} 
                                                    className="card-img-top-3x4 rounded-0" 
                                                    alt={itemForSale.item_name} 
                                                />
                                                <div className="card-body px-0 py-1">
                                                    <div className="fs-7 fw-medium text-truncate">{itemForSale.school_name}</div>
                                                    <div className="fs-7 mt-0 pt-0">{itemForSale.school_town}</div>
                                                    <div className="fs-5 fw-medium">£{itemForSale.price}</div>
                                                    <div className="fs-6 fw-bold text-capitalize">{itemForSale.item_name}</div>
                                                    <div className="d-flex align-items-baseline">
                                                        <div className="fs-7 me-1">size</div>
                                                        <div className="fs-6 text-capitalize">{itemForSale.size}</div>
                                                    </div>
                                                    
                                                    {itemForSale.school_donation_rate > 0 && (
                                                        <FontAwesomeIcon className="fs-6 text-primary" icon="fa-solid fa-star" />
                                                    )}
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        )} 
                    </div>
                </section>
            </div>                   
        </div>
    );
}

export default ItemsForSale;


