import React from 'react';
import { Link } from 'react-router-dom';

const EmailVerificationSuccessPage = () => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const isSchoolUser = userData?.user?.school_id !== null;

    return (
      
        <div className="container mt-5">
            <div className="text-center">
                {!userData ? (
                    <>
                    <h1>Email Verified Successfully</h1>
                    <p>
                        Your email has been successfully verified.<br/>
                        You can now log in to your account.
                    </p>
                    <Link to="/login" className="btn btn-primary">Login</Link>
                    </>
                ) : (
                    <>
                    <h1>Email Verified Successfully</h1>
                    <p>
                        Your email has been successfully verified.<br/>
                    </p>
                    <Link to={isSchoolUser ? "/school-dashboard" : "/dashboard"} className="btn btn-primary">Go to Dashboard</Link>
                    </>
                )}   
            </div>
        </div>
    );
};

export default EmailVerificationSuccessPage;
