import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const PasswordUpdateSuccess = () => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const isSchoolUser = userData?.user?.school_id !== null;

    return (
        <div className="container mt-5">
            <div className="text-center">
                <h1>Password Update Successful</h1>
                <p>
                    Your password has been successfully updated
                </p>
                <Link to={isSchoolUser ? "/school-dashboard" : "/dashboard"} className="btn btn-primary">Go to Dashboard</Link>
            </div>
        </div>
    );
};

export default PasswordUpdateSuccess;
