import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for fetching payout
export const fetchLatestPayout = createAsyncThunk('latestPayout/fetchLatestPayout', async () => {
  const response = await fetch(`/api/payment/latest-payout`);
  const data = await response.json();
  return data;
});

const latestPayoutSlice = createSlice({
  name: 'latestPayout',
  initialState: { latestPayout: null, status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLatestPayout.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLatestPayout.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.latestPayout = action.payload;
      })
      .addCase(fetchLatestPayout.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default latestPayoutSlice.reducer;
